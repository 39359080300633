import {datatableServiceUrl, postCall, constantMap} from "./ApiUtils";
import {getAccessToken, getTokenEmail} from "./Authentication";
import jwt_decode from 'jwt-decode';
// For todays date;
Date.prototype.today = function () {
  return (((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +""+((this.getDate() < 10)?"0":"") + this.getDate() +""+ this.getFullYear();
};

// For the time it is now
Date.prototype.timeNow = function () {
  return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
};// For the time now


// For the 2 digit hour it is now
Date.prototype.hourNow = function () {
  return ((this.getHours() < 10)?"0":"") + this.getHours();
};
Date.prototype.format = {
  dayMonthOf: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    if (dd < 10)
      dd = '0' + dd;
    if (mm < 10)
      mm = '0' + mm;
    date = mm + '/' + dd;
    return date;
  },
  monthDayYearOf: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();

    if (dd < 10)
      dd = '0' + dd;
    if (mm < 10)
      mm = '0' + mm;
    date = mm + '/' + dd + '/' + yyyy;
    return date;
  },
  yearMonthDayOf: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();

    if (dd < 10)
      dd = '0' + dd;
    if (mm < 10)
      mm = '0' + mm;
    date = yyyy + '/' + mm + '/' + dd;
    return date;
  },
  dayMonthYearTimeOf_Military: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var hh = date.getHours();
    var mM = date.getMinutes();

    if (dd < 10)
      dd = '0' + dd;
    if (mm < 10)
      mm = '0' + mm;
    if (hh < 10)
      hh = '0' + hh;
    if (mM < 10)
      mM = '0' + mM;
    date = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + mM;
    return date;
  },
  dayMonthYearTimeOf_AMPM: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var hh = date.getHours() > 12 ? hh = (date.getHours() - 12) : date.getHours();
    var mM = date.getMinutes();
    var ampm = date.getHours() > 11 ? 'PM' : 'AM';

    if (dd < 10)
      dd = '0' + dd;
    if (mm < 10)
      mm = '0' + mm;
    if (hh < 10)
      hh = '0' + hh;
    if (mM < 10)
      mM = '0' + mM;
    date = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + mM + ' ' + ampm;
    return date;
  },
  timeOf_Military: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var hh = date.getHours();
    var mM = date.getMinutes();

    if (hh < 10)
      hh = '0' + hh;
    if (mM < 10)
      mM = '0' + mM;
    date = hh + ':' + mM;
    return date;
  },
  timeOf_AMPM: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    var hh = date.getHours() > 12 ? hh = (date.getHours() - 12) : date.getHours();
    var mM = date.getMinutes();
    var ampm = date.getHours() > 11 ? 'PM' : 'AM';

    if (hh < 10)
      hh = '0' + hh;
    if (mM < 10)
      mM = '0' + mM;
    date = hh + ':' + mM + ' ' + ampm;
    return date;
  },
  yearOf: function (date) {
    if (date === undefined)
      date = new Date();
    else if (!date)
      return '';
    date = new Date(date);
    return date.getFullYear();
  }
}


function formatText(content) {
  // Replace spaces with &nbsp;
  //let formattedContent = content.replace(/ /g, '&nbsp;');
  // Replace line breaks with <br> tags
  let formattedContent = content ? content.replace(/\n/g, '<br>') : "";
  return formattedContent;
}
export function customPrompt(message, defaultValue = '') {
  return new Promise((resolve) => {
    // Remove existing prompt if still present
    const existing = document.getElementById('customPromptOverlay');
    if (existing) existing.remove();

    // Create overlay
    const overlay = document.createElement('div');
    overlay.id = 'customPromptOverlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100vw';
    overlay.style.height = '100vh';
    overlay.style.backgroundColor = 'rgba(0,0,0,0.5)';
    overlay.style.display = 'flex';
    overlay.style.alignItems = 'center';
    overlay.style.justifyContent = 'center';
    overlay.style.zIndex = '10000';

    // Create prompt box
    const box = document.createElement('div');
    box.style.backgroundColor = 'white';
    box.style.padding = '20px';
    box.style.borderRadius = '10px';
    box.style.boxShadow = '0 2px 10px rgba(0,0,0,0.3)';
    box.style.maxWidth = '90%';
    box.style.width = '330px';

    const label = document.createElement('div');
    label.textContent = message;
    label.style.marginBottom = '10px';

    const input = document.createElement('textarea');
    input.value = defaultValue;
    input.rows = 4;
    input.style.width = '100%';
    input.style.padding = '8px';
    input.style.marginBottom = '10px';
    input.style.boxSizing = 'border-box';

    const buttonContainer = document.createElement('div');
    buttonContainer.style.display = 'flex';
    buttonContainer.style.justifyContent = 'space-between';

    const okBtn = document.createElement('button');
    okBtn.textContent = 'OK';
    okBtn.style.flex = '1.5';

    const cancelBtn = document.createElement('button');
    cancelBtn.textContent = 'Cancel';
    cancelBtn.style.flex = '1';
    cancelBtn.style.marginRight = '5px';

    okBtn.onclick = () => {
      resolve(input.value);
      overlay.remove();
    };

    cancelBtn.onclick = () => {
      resolve(null);
      overlay.remove();
    };

    buttonContainer.appendChild(cancelBtn);
    buttonContainer.appendChild(okBtn);

    box.appendChild(label);
    box.appendChild(input);
    box.appendChild(buttonContainer);
    overlay.appendChild(box);
    document.body.appendChild(overlay);

    input.focus();
  });
}
function loadScript(url, callback) {
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';
  script.onload = () => {
    if (callback) callback();
  };
  document.head.appendChild(script); // or document.body
}
export function leaveOpenPoint() {
  let url = window.location.href;
  customPrompt("Leave an open-point to help improve our app.").then((issue) => {
    if (issue) {
      const entityUuid = getAccessToken() ? jwt_decode(getAccessToken()).entityUuid || undefined : undefined;
      const customValueUUID = "11111111-1111-1111-1111-111111111111";
      const currentUser = getTokenEmail();
      let id = uuid();
      const openpoint = {
        uuid: id,
        openDate: (new Date()).format.monthDayYearOf(),
        openedBy: {
          uuid: entityUuid || customValueUUID,
          entityName: currentUser || undefined
        },
        actionRequired: issue,
        reference: url,
        openpointType: "page",
        context: constantMap.GATEWAY_TITLE + "Gateway",

        version: 1,
        createdAtTz: new Date().toLocaleString(),
        createdByUser: currentUser,
        createdInEnv: constantMap.GATEWAY_TITLE
      };
      postOpenPoint(openpoint);
    }
  });
  function uuid() {
    let lut = [];
    for (let i = 0; i < 256; i++) {
      lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
    }
    let d0 = Math.random() * 0xffffffff | 0;
    let d1 = Math.random() * 0xffffffff | 0;
    let d2 = Math.random() * 0xffffffff | 0;
    let d3 = Math.random() * 0xffffffff | 0;
    return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
        lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
        lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
        lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
  }
  function postOpenPoint(record){
    const recordT = {
      uuid: record.uuid,
      listTitle: "Open_Points",
      objectType: "Openpoint",
      record: record,
      version: record.version
    }
    postCall(`${datatableServiceUrl}record`, recordT, false, false, true);
  }
}