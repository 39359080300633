import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/MeetingRoom';
import './UserMenu.css';
import {leaveOpenPoint} from "../utils/utils";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Link} from "react-router-dom";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? '#125399' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function UserMenu({loggedIn, userIsAdmin, setLoggedIn, firstName, lastName, onAboutModalOpen}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAboutClick = () => {
        handleClose();
        onAboutModalOpen();
    };


    return (
        <div className="user-menu">
            <Button
                id="user-menu-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                // endIcon={<KeyboardArrowDownIcon />}
            >&#8942;
                {/*<UserIcon />*/}
                {/*{firstName + " " + lastName}*/}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {handleClose(); leaveOpenPoint();}} disableRipple>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAQAAAD8x0bcAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+kDGgEEDgeNr/UAAAEHSURBVCjPhdLNKoRhGAbg6/3mKzNIURQ1C8XKjoSlKAtZWdlQSDkMB+AUrEZxABayIDs7PwtrZtaSNOFjXpvBjPFzL5+ueu/n6c35K3lziirpn2RTVZdaKtGjlUaJRbN2ZNqCeZMeWkiHUecKxhzZTg07cCbXQGrarbmQuFd2LUtFr6LXpi4regSZiisJyQ911/VJZCpKnoVWlLeq95PUBFrRhvH6QyUvH8NmNKQfA24biW8XWnDnRdmurHHciAZNOLan/H2XVNChICdKbLlBp6gqKHjTLpI6N21KQBQl9XufYsa9vH0CCk33Juq2LHXoUvT0+w9YcqDv5+2+cmLEo38SFL9KvANNf0bwdQhMkgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNS0wMy0yNlQwMTowMzo1NSswMDowME+Xrf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjUtMDMtMjZUMDE6MDM6NTUrMDA6MDA+yhVBAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI1LTAzLTI2VDAxOjA0OjE0KzAwOjAwqT4qqQAAAABJRU5ErkJggg==" alt="" />
                    <Link to="#"
                          style={{ textDecoration: 'none', color: 'inherit', marginLeft: '12px' }}
                    >Leave Open Point</Link>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <UserIcon sx={{
                        marginRight:6
                    }}/>
                    <Link to="/account"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                    >Account</Link>
                </MenuItem>
                { userIsAdmin() ? <MenuItem onClick={handleClose} disableRipple>
                    <AdminPanelSettingsIcon />
                    <Link to="/admin"
                          style={{ textDecoration: 'none', color: 'inherit'}}
                    >Admin</Link>
                </MenuItem> : ''}
                <MenuItem onClick={handleAboutClick} disableRipple>
                    <InfoIcon sx={{
                        marginRight:6
                    }}/>
                    <span style={{ textDecoration: 'none', color: 'inherit' }}>About</span>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple className={loggedIn === true ? '' : 'hidden'}>
                    <LogoutIcon />
                    <Link to="/logout"
                          onClick={() => {setLoggedIn(false);}}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                    >Logout</Link>
                </MenuItem>
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <ArchiveIcon />*/}
                {/*    Archive*/}
                {/*</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <MoreHorizIcon />*/}
                {/*    More*/}
                {/*</MenuItem>*/}
            </StyledMenu>
        </div>
    );
}

/**
 *
 *               <li>
 *                 <Link to="/logout" className={loggedIn === true ? 'underline-link' : 'hidden'} onClick={() => {
 *                   setLoggedIn(false);
 *                   // logout();
 *                 }}>Logout</Link>
 *               </li>
 *               <li>
 *                 <Link to="/admin"
 *                       className={loggedIn === true && userIsAdmin() ? 'underline-link' : 'hidden'}>Admin</Link>
 *               </li>
 */