import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

function EnhancedSelect({ entities, newUser, setNewUser, ...props }) {
    const handleChange = (event, value) => {
        // Assuming you want to store the selected entity's UUID
        setNewUser(prevState => ({ ...prevState, entityUuid: value.uuid }));
    };

    return (
        <Autocomplete
            value={entities.find(entity => entity.uuid === newUser.entityUuid) || null}
            onChange={handleChange}
            options={entities}
            getOptionLabel={(option) => option.entityName}
            renderInput={(params) => (
                <TextField {...params} label="Entity" variant="outlined"
                           inputProps={{
                               ...params.inputProps, // Preserve existing props
                               style: { padding: '0 0 .6rem 0' } // Apply your padding
                           }} />
            )}
            fullWidth
            {...props}
        />
    );
}

export default EnhancedSelect;
