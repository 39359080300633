import React, {useState, useEffect} from 'react';
import {getCall, getUrl, postCall, urls, fetchEntities} from "../../utils/ApiUtils";
import UsersTable from "./UsersTable";
import UserModal from "./UserModal";
import './User.css';
import {search} from "../../utils/TableUtils";
import {makeStyles} from "@mui/styles";
import {resetPassword} from "../../utils/Authentication";


/**
 *
 * @param
 * @constructor
 */
const UsersPage = ( {users: _users = [], setPageTitle} ) => {
    //state variables
  const [users, setUsers] = useState([]);
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [haveEntitiesBeenFetched, setHaveEntitiesBeenFetched] = useState(false);

  const handleEdit = async (user) => {
    if (!haveEntitiesBeenFetched) {
      let records = await fetchEntities();
      setEntities(records);
      setHaveEntitiesBeenFetched(true);
    }
    if(user) {
      setSelectedUser(user);
      setIsModalOpen(true);
    }
  }

  const handleClose = () => {
    setIsModalOpen(false);
  }

  const handleSave = async (user, password, changePassword) => {
    // update user in your data source
    if(changePassword && password) {
      resetPassword(user.uuid, password);
    }
    else if(user) {
      if(!Array.isArray(user.roles)) {
        user.roles = user.roles.split(",");
      }
      await postCall(getUrl('updateUser'), user, false);
      fetchUsers();
    }
    setIsModalOpen(false);
  }
  const handleDelete = async (user) => {
    // Ask for user confirmation before proceeding
    const isConfirmed = window.confirm("Are you sure you want to delete this user account?");

    if (isConfirmed && user) {
      // If confirmed, proceed with the deletion
      await postCall(getUrl('deleteUser'), user, false);
      fetchUsers();
    }

    setIsModalOpen(false);
  };

  //runs when commponent is first loaded
  useEffect(() => {
    setPageTitle("Users");
    fetchUsers();
  }, [setPageTitle]);

  //Retrieve users, if it's passed an email, gets that user, if it's not it gets all users
  const fetchUsers = async (email) => {
    let users;
    if(email)
      users = await getCall(getUrl('getUserProfile', [email, -1]));
    else
      users = await getCall(urls['getUserProfiles']);

    setUsers(users)
    setLoading(false);
  };

    return (
      <div>
        <br></br>
        {/*<div><Link to="/register" className='underline-link'>Add User</Link></div>*/}
        <div class='search'>
          <label class='search-label'>Search Page</label><br/>
          <input type="text"
                 id="search"
                 onKeyUp={() => search('#users', '#search')}
                 placeholder="Search list.."
                 title="Type to search."/>
        </div>
        {!loading && <UsersTable users={users} onEdit={handleEdit} />}
        {isModalOpen && !loading && selectedUser && haveEntitiesBeenFetched &&
            <UserModal open={isModalOpen} entities={entities} user={selectedUser} handleClose={handleClose} handleSave={handleSave} handleDelete={handleDelete}/>}
      </div>
    );
};

export default UsersPage;
