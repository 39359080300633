import React, {} from "react";
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import {Navigate} from "react-router";
import {postCall, getUrl, getCall} from "./ApiUtils";

//Retrieves the access cookie, and refresh cookie
let TOKEN;
export const getAccessToken = () => {
  return TOKEN || (TOKEN = Cookies.get('access-token'));

}


//If there is a result returned from the getAccessToken we are authenticated
export const isAuthenticated = () => !!getAccessToken();

//If there is a refresh token, try getting a new access token
// export const authenticate = async () => {
//     if(getAccessToken()) {
//         try {
//             const tokens = await refreshTokens(); //call an API, returns tokens
//
//             setTokenCookie(tokens);
//
//             return true;
//         } catch (error) {
//             return false;
//         }
//     }
// }

export function setTokenCookie({token, roles}) {
  if (!token) {
    TOKEN = undefined;
    Cookies.remove('access-token');
  } else {
    let expiration = getTokenExpirationDate(token);
    Cookies.set('access-token', token, {expires: expiration});
  }
}

export async function getUserProfile() {
  let email = getTokenEmail();
  if (!email)
    return undefined;

  let user = await getCall(getUrl("getUserProfile", [email]));
  return user;
}

export const login = async (username, password) => {
  const formData = new URLSearchParams();
  formData.append('username', username);
  formData.append('password', password);
  return postCall(getUrl('login'), formData, true, true)
      // .then(result => {
      //   TOKEN = result.token;
      //   setTokenCookie(result.token);
      // })
      .catch(e => {
    throw new Error('Username or password was incorrect');
  });
}

export const logout = () => {
  let token = getAccessToken();
  setTokenCookie({});
  getCall(getUrl('logout', []), false, {"X-LOGOUT-CODE": token});
  console.log('Logged out');
}

export const register = async (newUser) => {
  await postCall(getUrl('register'), newUser, false).then(result => {
    // setTokenCookie(result);
  }).catch(e => {
    throw new Error(e.message);
  });
}

export const updatePassword = async (username, oldPassword, newPassword) => {
  await postCall(getUrl('updatePassword'), {username, oldPassword, newPassword}).then(result => {
    setTokenCookie(result);
  });
}
export const resetPassword = async (uuid, newPassword) => {
  await postCall(getUrl('resetPassword'), {uuid, newPassword}).then(result => {
    alert("Password change successful.");
  });
}
// const requestResetToken = async (email) => {
//   return await postCall(getUrl('forgotPassword'), {email}, true);
// }

// const sendPasswordReset = async (email, resetToken, newPassword) => {
//   return await postCall(getUrl('resetPassword'), {email, resetToken, newPassword}, true);
// }

// const tokenIsValid = (token) => {
//   const tokenExpirationDate = getTokenExpirationDate(token);
//   if (tokenExpirationDate === undefined) {
//     return false;
//   }
//   return (tokenExpirationDate.valueOf() > new Date().valueOf());
// }
export const RequireAuth = ({ children, role }) => {
  console.log("----hit RequireAuth---- authenticated:" + isAuthenticated() + " has role: " + hasRole(role));
  return isAuthenticated() ? (hasRole(role) ? children : <div>Not Authorized</div>) : <Navigate to="/login" />;
};

// export const AuthenticatedRoute = ({
//                                      children,
//                                      exact,
//                                      path,
//                                      role
//                                    }) => {
//   if (isAuthenticated()) {
//     window.top.postMessage("login successful", "*");
//     console.log("sent message");
//   }
//   return (<Route
//     exact={exact}
//     path={path}
//     render={props =>
//       isAuthenticated() ? (hasRole(role) ? (<>{children}</>) : (<div>Not Authorized</div>)
//       ) : (
//         <AuthenticateBeforeRender render={() => <>{children}</>} {...props}/>
//       )
//     }
//   />);
// }

//Decode the token and return the email
export const getTokenEmail = () => {
  if(getAccessToken()) {
    const decodedToken = jwt_decode(getAccessToken());
    const tokenEmail = decodedToken.sub;
    if (tokenEmail && tokenEmail.length !== 0) {
      return tokenEmail;
    }
  }
  return '';
}

//Decode the token and return the roles
const getTokenRoles = () => {
  if(getAccessToken()) {
    const decodedToken = jwt_decode(getAccessToken());
    const tokenRoles = decodedToken?.role || [];
    return tokenRoles;
  }
  return [];
}

//Decode the token and return the expiration date
const getTokenExpirationDate = (token) => {
  if(token) {
    const decodedJWT = jwt_decode(token);

    if (decodedJWT.exp === undefined || !decodedJWT.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decodedJWT.exp);
    return date;
  }
  return null;
}

export const userIsAdmin = function () {
  return getTokenRoles().includes("ROLE_admin");
}

export const hasRole = function (role) {
  return getTokenRoles().includes("ROLE_" + role);
}

// const AuthenticateBeforeRender = function (props) {
//   let navigate = useNavigate();
//
//   const redirectToLogin = () => {
//     navigate('/login');
//   };
//   useEffect(() => {
//     redirectToLogin();
//   });
//
//   if (isAuthenticated()) {
//     window.top.postMessage("login successful", "*");
//     console.log("sent message");
//     return props.render();
//   }
//
//   return null;
//
// }
